import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import incident from './if_incident';


function handleDrop(ev:DragEvent){
  console.log('File(s) dropped');
  ev.preventDefault();

  if (ev.dataTransfer.items) {
    for (let i = 0; i < ev.dataTransfer.items.length; i++) {
      if (ev.dataTransfer.items[i].kind === 'file') {
        var file = ev.dataTransfer.items[i].getAsFile();
        console.log('... file[' + i + '].name = ' + file.name);
      }
    }
  } 
  else {
    for (let l = 0; l < ev.dataTransfer.files.length; l++) {
      console.log('... file[' + l + '].name = ' + ev.dataTransfer.files[l].name);
    }
  }
}

function getIRfromURL(){
  return document.location.pathname.replace("/incident/","");
}

function removeError(e:any){
  let ent = e.target as HTMLFormElement;
  ent.classList.remove("error");
}

function submitRequestForNotifications(e:any){
  console.info(e);
}

function sendMagicLink(e:any){
  let emv = document.getElementById("email") as HTMLInputElement;
  if((/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emv.value))){
    alert("Yes, this would happen");
  }
  else{
    emv.classList.add("error");
  }
}

function ReviewIncident(props: any) {
    return (
      <>
        <section className="intro">
          <div className="headline">Your Incident Report { getIRfromURL() } has been opened</div>
          <div className="caption">Use this case number for any follow-ups you might have for us.</div>
        </section>
        <form>
          <section>
            <div className="inputWrapper">
              <div className="sectionHeader">Do you have any supporting materials to share?</div>
              <div className="caption">This could be photos, videos or anything else that’s useful.</div>
            </div>
            <div className="dropTarget" onDrop={ (e) => handleDrop(e) }>
              <FontAwesomeIcon icon={faCloudUploadAlt} color="#fff" size="2x" />
              <div className="dropTargetPrompt">Drop files here, or tap to upload from your computer.</div>
            </div>
          </section>
          
          <section>
            <div className="inputWrapper">
              <div className="sectionHeader">Would you like to receive email updates on this incident?</div>
              <div className="caption">We can update you as your incident is reviewed.</div>
            </div>
            <div className="buttonWrapper">
              <input type="submit" className="simple_button" onClick={ (e) => submitRequestForNotifications(e) } value="Yes, Keep Me Updated" />
            </div>
          </section>
        </form>
      </>
    );
  }

  function EmptyState(){
    return(
      <>
        <section className="intro">
          <div className="headline">Sorry, we didn't find that Incident Report</div>
          <div className="caption">You may not be recognized</div>
        </section>
        <form>
          <section>
            <div className="inputWrapper">
              <div className="sectionHeader">Need to log back in?</div>
              <div className="caption">Input your email address below for a quick link.</div>
            </div>
            <div className="inputGroup">
              <label htmlFor="email">Email address:</label>
              <input type="email" id="email" onKeyDown={ (e) => removeError(e) } name="email" placeholder="Which email was used?" />
            </div>
            <div className="buttonWrapper">
              <input type="submit" className="simple_button" onClick={ (e) => sendMagicLink(e) } value="Send Magic Link" />
            </div>
          </section>
        </form>
        
      </>
    )
  }
  

  function Incident(props:any){
    let formdata = localStorage.getItem("forminput");
    if(formdata.length > 10){
      let irdata:incident = JSON.parse(formdata);

      window.onunload = function(){
        localStorage.setItem("forminput", "");
      };
      
      return(
        <ReviewIncident irdata={ irdata } />
      )
    }
    else{
      return(
        <EmptyState />
      )
    }
  }
  
  export default Incident;
  