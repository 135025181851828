import React, { Component } from 'react'
import Select from 'react-select'

const ngbs = [
    'U.S. Biathlon Association', 'U.S. Bowling Congress', 'U.S. Equestrian Federation', 'U.S. Figure Skating', 'U.S. Paralympic Snowboarding', 'U.S. Paralympics Alpine Skiing', 'U.S. Paralympics Cycling', 'U.S. Paralympics Nordic Skiing', 'U.S. Paralympics Swimming', 'U.S. Paralympics Track & Field', 'U.S. Soccer', 'U.S. Squash', 'U.S. Tennis Association', 'United States Association of Blind Athletes', 'US Sailing', 'US Ski & Snowboard', 'US Speedskating', 'USA Archery', 'USA Artistic Swimming', 'USA Badminton', 'USA Baseball', 'USA Basketball', 'USA Bobsled/Skeleton', 'USA Boccia', 'USA Boxing', 'USA Climbing', 'USA Curling', 'USA Cycling', 'USA Diving', 'USA Fencing', 'USA Field Hockey', 'USA Golf', 'USA Gymnastics', 'USA Hockey', 'USA Judo', 'USA Luge', 'USA National Karate-do Federation', 'USA Pentathlon', 'USA Racquetball', 'USA Roller Sports', 'USA Rugby', 'USA Shooting', 'USA Skateboarding', 'USA Softball', 'USA Surfing', 'USA Swimming', 'USA Table Tennis', 'USA Taekwondo', 'USA Team Handball', 'USA Track &amp; Field', 'USA Triathlon', 'USA Volleyball', 'USA Water Polo', 'USA Water Ski &amp; Wake Sports', 'USA Weightlifting', 'USA Wrestling', 'USOPC', 'US Rowing', 'Other'
]

export function ngbListAsOptions(){
    return(
        <>
            <option value="">Unknown / Not Applicable</option>
            { ngbs.map( (n, i) => <option key={ i } value={ n }>{ n }</option> ) }
        </>
        
    )
}

export function NGBSelect(){
    let options = []
    for(let x=0; x< ngbs.length; x++){
        options.push({ value: ngbs[x], label: ngbs[x] })
    }
    return(
        <Select name="ngb_affiliation_multi" className="reactSelect" isMulti={ true } options={options} />
    )
}