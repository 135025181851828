import { ReactComponent as Logo } from './logo.svg';
import './index.css';


function USCSSLogo() {
    return (
      <a href="/"><div id="logo">
        <Logo />
      </div></a>
    );
  }
  
  function Header() {
    return(
      <header>
        <div className="logo">
          <USCSSLogo />
        </div>
        <div className="phoneWrapper">
          <div className="phoneCallout">
            <a href="tel:+18335877233">(833) 587-7233</a>
          </div>
          <div className="phoneSupport">
            Agents available M-F 9am-5pm MT
          </div>
        </div>
      </header>
    )
  }


  export default Header;