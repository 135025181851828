import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import InitialForm from './InitialForm';
import Incident from './incident';
import AddToCase from './AddToCase';
import Header from './header';


ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
          <Route path="/case/:id">
            <Header />
            <AddToCase />
          </Route>
          <Route path="/incident/:id">
            <Header />
            <Incident />
          </Route>
          <Route exact path="/">
            <Header />
            <InitialForm />
          </Route>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

