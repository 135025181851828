import './index.css';

function AddToCase(props: any) {
    return (
      <section>
        <div className="text">
          <div className="section_title">Case Information Screen</div>
          <div className="section_caption">Last updated yesterday</div>
        </div>
      </section>
    );
  }
  
  
  export default AddToCase;
  