import React, { useState, KeyboardEvent, ChangeEvent, Component} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './index.css';
import incidentParty from './if_incidentParty';
import { ngbListAsOptions, NGBSelect } from './ngbs';
import Select from 'react-select'

function formatPhone(str:string){
  let ns = str.replace(/[^\d]+/g, '');
  if(ns.length === 10){
    return "(" + ns.substring(0,3) + ") " + ns.substring(3,6) + "-" + ns.substring(6,);
  }
  else{
    return str;
  }
}

let orgRoles = ['', 'Athlete', 'Attorney', 'Billet', 'Board Member', 'Coach/Trainer', 'LAO Owner', 'LAO Administrator', 'Law Enforcement', 'Medical Staff', 'NGB Employee', 'Official', 'Other', 'Parent/Legal Guardian', 'Unknown', 'USOPC Employee', 'Volunteer/Contractor'];
let advisorRoles = ['Attorney', 'Parent/Legal Guardian'];
let roles = ["", "respondent", "claimant", "witness", "advisorForClaimant", "advisorForRespondent", "tpr"];
const rolesLookupTable = {
  claimant: "Claimant (i.e. Victim)",
  respondent: "Respondent (i.e. Accused)",
  witness: "Witness",
  advisorForClaimant: "Advisor for Claimant",
  advisorForRespondent: "Advisor for Respondent",
  tpr: "Unknown"
};

function MakeRoleSelect(){
  return(
    <div className="mobileSelectWrapper">
      <select name="orgRole">
        { orgRoleListAsOptions() }
      </select>
    </div>
  )
}

function getTextForRole(role:string){
  if(role in rolesLookupTable){
    return rolesLookupTable[role];
  }
  else{
    return "";
  }
}

function orgRoleListAsOptions(){
  return(
    <>
        { orgRoles.map( (n, i) => <option key={ i } value={ n }>{ n }</option> ) }
    </>
  )
}

function MakeOrgRoleSelect(){
  return(
    <div className="mobileSelectWrapper">
      <select name="orgRole" >
        { orgRoleListAsOptions() }
      </select>
    </div>
  )
}

function MakeNGAffiliationSelect(name:string, size:number, selected:string[]){
  let multiple = size > 1 ? true : false;
  let selectedValue = selected.length === 0 ? "" : selected;
  return(
    <select name={ name } defaultValue={ selectedValue } multiple={ multiple } size={ size } >
      { ngbListAsOptions() }
    </select>
  )
}

function MakePartyRoleSelect(role:string){
  return(
    <select defaultValue={ role } name="role">
      { roles.map( (r, i) => <option key={ i } value={ r } >{ getTextForRole(r) }</option> ) }
    </select>
  )
}



function Intro() {
  return(
    <section className="intro">
      <div className="headline">Filing a New Incident Report</div>
      <div className="caption">We’re here for you — and here to help.  This form should only take a few minutes to complete. </div>
    </section>
  )
}

function Form(){
  /*

  This is the main component, intentionally monolithic to allow for the individual submitting the form
  to be shown as a party. The submit button calls submitIncident()

  */


  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalState,setModalState] = useState("");
  const [partiesList,updatePartiesList] = useState<incidentParty[]>([]);
  const [incidentCharCount,updateIncidentCharCount] = useState(3000);
  const [partiesViewAsIcons, setPartiesViewAsIcons] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editingID, setEditingID] = useState(-1);
  const [multipleDates, setMultipleDates] = useState(false);
  const [missingRespondent, setMissingRespondent] = useState(false);

  function MakePartyInputForm(role:string){
    const [partyRole, setPartyRole] = useState(role);
    let upperRole = partyRole.charAt(0).toUpperCase() + partyRole.substring(1);
    
    return(
      <form>
        <div className="sectionHeader">Add a new person to this Incident Report</div>
        <div className="inputGroup space_top">
          <label htmlFor="name">Party type:</label>
          <div className="mobileSelectWrapper">
            <select defaultValue={ role } name="role" onChange={ (e: React.ChangeEvent<HTMLSelectElement>) => setPartyRole(e.target.value) }>
            { roles.map( (r, i) => <option key={ i } value={ r } >{ getTextForRole(r) }</option> ) }
            </select>
          </div>
        </div>
        <div className="inputGroup">
          <label htmlFor="name">Their name:</label>
          <input name="name" placeholder={ role === "" ? "Their full name" : upperRole + " full name"} />
        </div>
        <div className="inputGroup">
          <label htmlFor="party_ngb_affiliation">Their NGB affiliation:</label>
          { <NGBSelect /> }
        </div>
        <div className="inputGroup">
          <label htmlFor="clubAffiliation">Their club affilation:</label>
          <input name="clubAffiliation" placeholder="Leave blank if you don’t know, or it’s not applicable" />
        </div>
        <div className="inputGroup">
          <label htmlFor="orgRole">Their role:</label>
          { MakeOrgRoleSelect() }
        </div>
        <div className="inputGroup">
          <label htmlFor="phoneNumber">Their phone number:</label>
          <input type="tel" name="phoneNumber" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$" placeholder="Do you know their phone number?" />
        </div>
        <div className="inputGroup">
          <label htmlFor="email">Their email address:</label>
          <input type="email" name="email" placeholder="Do you know their email address?" />
        </div>
        <div className="inputGroup">
          <label htmlFor="dateOfBirth">Their date of birth:</label>
          <input type="date" name="dateOfBirth" placeholder="Do you know their date of birth?" />
        </div>
        <button className="simple_button" onClick={ processForm }>Add to Report</button><button className="simple_button" type="reset">Start Over</button>
      </form>
    )
  }

  function updateCharCount(){
    let ta = document.getElementsByName("incident_summary")[0] as HTMLFormElement;
    updateIncidentCharCount(3000 - ta.value.length);
  }
  
  function addReporterToParties(e:any){
    let elementsList = e.target.form.elements;
    
    let reporterName = (document.getElementsByName("name")[0] as HTMLFormElement).value
    let reporterEmail = (document.getElementsByName("email")[0] as HTMLFormElement).value
    let reporterNGB = []
    let reporterRole = (document.getElementsByName("role")[0] as HTMLFormElement).value;
    let orgRole = (document.getElementsByName("orgRole")[0] as HTMLFormElement).value;

    console.log(elementsList);

    reporterNGB.push(elementsList.ngb_affiliation_multi.value);
    
    if(elementsList.ngb_affiliation_multi.length > 1){
      for(let x=0;x<elementsList.ngb_affiliation_multi.length;x++){
        reporterNGB.push(elementsList.ngb_affiliation_multi[x].defaultValue);
      }
    }
    
    
    let reporterObject:incidentParty = {
      name: reporterName,
      email: reporterEmail,
      sportOrgAffiliation: reporterNGB,
      orgRole: orgRole,
      role: reporterRole,
      id: "",
      reporter: true,
      phoneNumber: ""
    };

    let hasDuplicate = false;
    for(let x=0;x<partiesList.length;x++){
      if(partiesList[x].name === reporterObject.name){
        // duplicate
        hasDuplicate = true;
        partiesList[x] = reporterObject
      }
    }
    if(!hasDuplicate){
      partiesList.push(reporterObject);
    }
    let newPartiesList = [...partiesList]; // it has to be unique to force the re-render!
    updatePartiesList(newPartiesList);
  }

  function addParty(object:incidentParty){
    partiesList.push(object);
    updatePartiesList(partiesList);
    return true;

    // dead code past here due to change in requirements

    let shouldUpdate = true;
    for(let x=0;x<partiesList.length;x++){
      if(object.name === partiesList[x].name){
        shouldUpdate = false;
      }
      if(object.role === "claimant" && object.role === partiesList[x].role){
        shouldUpdate = false;
      }
      if(object.role === "respondent" && object.role === partiesList[x].role){
        shouldUpdate = false;
      }
    }
    if(shouldUpdate){
      partiesList.push(object);
      updatePartiesList(partiesList);
    }
  }

  const setModalIsOpenToFalse =()=>{
      setModalIsOpen(false)
  }
  function makeOpenModal(e:any){
    setModalIsOpen(true);
    activateSection(e.target.id);
  }

  function makeEditModal(i:any){
    setModalIsOpen(true);
    setEditing(true);
    setEditingID(parseInt(i, 10));
    activateSection("edit");
  }

  function activateSection(id){
    setModalState(id);
  }

  function roleIsNeeded(r:string){
    /* 

    This function is commented out for now. We need to validate the party requirements with
    the Center. Right now, the only required party is a Respondent. 

    */

    // for(let x=0;x<partiesList.length; x++){
    //   if(partiesList[x].role === r || (document.getElementById("relationship") as HTMLFormElement).value === r){
    //     return false;
    //   }
    // }
    return true;
  }

  

  function getUXString(s:string){
    
    // TODO: this should be replaced with the i18n approach for externalizing all strings

    if(s === "claimant"){
      return "Claimant";
    }
    else if(s === "respondent"){
      return "Respondent";
    }
    else if(s === "witness"){
      return "Witness";
    }
    else if(s === "advisor"){
      return "Advisor";
    }
    else{
      return s;
    }
  }

  function getPartyName(s:string){
    if((document.getElementsByName("name")[0] as HTMLFormElement).value === s){
      return s + " (You)";
    }
    return s;
  }

  function getID(s:string){
    if(s === "claimant"){
      return "newClaimant";
    }
    return "newRespondent";
  }

  function processForm(e:any){

    /* GJ notes on processForm()
    This function handles both data validation as well as compilation of the final data object
    for adding an individual as a party to the incident report. 

    It was written before the GraphQL libraries were even remotely close to finishing, so there are 
    clear gaps between the local data model and the expectations from the GraphQL library ("armstrong").
    
    The function parses the form at e.target.parent and stores the values as an incidentParty object,
    then calling addParty() to push up the party to the parent function.
    
    */

    e.preventDefault();
    
    let form = e.target.parentElement;
    let elementsList = form.elements;
    let formElements = [];

    let returnObject:incidentParty = {
      name: "", 
      role:"", 
      annotation: "", 
      email: "", 
      phoneNumber: "", 
      dateOfBirth: "",
      id: "",
      sportOrgAffiliation: [],
      reporter: false
    };

    for(let x=0; x<elementsList.length; x++){
      if(elementsList[x].tagName === "INPUT"){
        if(elementsList[x].name === "phoneNumber"){
          returnObject.phoneNumber = formatPhone(elementsList[x].value);
        }
        else{
          returnObject[elementsList[x].name] = elementsList[x].value;
        }
      }
      
      if(elementsList[x].name === "ngb_affiliation_multi"){
        returnObject.sportOrgAffiliation.push(elementsList[x].value);
      }
      if(elementsList[x].name === "role"){
        returnObject.role = elementsList[x].value;
      }
    }
    
    for (let x=0; x<formElements.length;x++){
      returnObject[formElements[x].name] = formElements[x].value
    }
    
    
    // console.info(returnObject);
    addParty(returnObject);
    setModalIsOpenToFalse();
  }

  function resetForm(e:any){
    updatePartiesList([]);
  }

  function isValidPhone(v:string){
    return /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(v) || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v);
  }

  function isValid(hf:HTMLFormElement){
    // required, not empty and applying special patterns
    if(hf.name === "email" && hf.value !== "" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(hf.value))){ hf.classList.remove("error"); return true; } // needs to be a valid email address
    else if(hf.name === "incident_date" && hf.value !== "" && hf.value <= new Date().toISOString().split("T")[0]){ hf.classList.remove("error"); return true; } // can't be in the future
    else if(hf.name === "phone" && hf.value !== "" && isValidPhone(hf.value)){ return true; }
    else if(hf.name === "incident_summary" && hf.value !== ""){ return true; }
    else if(hf.name === "incident_location" && hf.value !== ""){ return true; }

    // optional or all option values accepted
    if(["name", "orgRole", "email", "phone", "clubAffiliation", "role", "reported_to_lawenforcement", "multipleDates", "ngb_affiliation", "incident_environment", "type_of_concern", "minor_involved"].includes(hf.name)){
      return true;
    }
    
    // return the errors
    hf.classList.add("error");
    // console.info(hf.name);
    return false;
  }

  function submitIncident(e:any){

    /*
    GJ notes on submitIncident()

    This is the primary function for submitting the form. It was built before the data model was
    finalized or the GraphQL library was completed, so there are clear gaps between the data format
    this function returns and the actual needs of the GraphQL mutations. 

    Because this was built to mock the interface, the function stashes the actual data into
    localStorage but that's both unnecessary and undesirable in production. We should rip that out
    as the armstrong is hooked up to hancock. 
    */

    e.preventDefault();
    let intakeForm = document.getElementById("intakeForm") as HTMLFormElement;
    let formElements = intakeForm.elements;
    let formdata = {
      case_stage: "intake",
      intake_method: "online",
      related_cases: [],
      tpr_language: "english",
      parties: []
    };
    let reporter = {} as incidentParty;
    let readyToSubmit = true;
    for(let x=0;x<formElements.length;x++){
      if(!formElements[x].hasAttribute("name")){
        continue;
      }
      let thisEl = formElements[x] as HTMLFormElement;
      let isOk = isValid(thisEl);
      if(!isOk){
        readyToSubmit = false;
      }
      if(["name", "email", "ngb_affiliation", "clubAffiliation", "role"].includes(thisEl.name)){
        // take all of the inputs about the reporter and add to the parties object
        reporter[thisEl.name] = thisEl.value;
      }
      else{
        formdata[thisEl.name] = thisEl.value;
      }
    }

    let shouldPushReporterIntoPartiesList = true;
    for(let i=0;i<partiesList.length;i++){
      if(partiesList[i].name === reporter.name){
        shouldPushReporterIntoPartiesList = false;
      }
    }
    if(shouldPushReporterIntoPartiesList){
      formdata.parties.push(reporter);
    }

    for(let y=0;y<partiesList.length;y++){
      formdata["parties"].push(partiesList[y]);
    }
    let newID = "IR2022-99".concat((Math.round(Math.random()*100)).toString());
    formdata["incident_id"] = newID;

    if(formdata.parties.length === 0) { 
      readyToSubmit = false; 
    }
    else{
      readyToSubmit = true;
      let hasRespondent = false;
      for(let boop = 0; boop < formdata.parties.length; boop++){
        if(formdata.parties[boop].role === "respondent"){
          hasRespondent = true;
        }
      }
      if(!hasRespondent){
        readyToSubmit = false;
        setMissingRespondent(true);
      }
      else{
        setMissingRespondent(false);
      }
    }
    

    if(readyToSubmit){
      localStorage.setItem("forminput", JSON.stringify(formdata));
      document.location.href = "/incident/" + newID;
    }
  }

  function removeError(e:KeyboardEvent){
    let ent = e.target as HTMLFormElement;
    ent.classList.remove("error");
  }

  function removeErrorViaChange(e:ChangeEvent){ // this is a stupid redeclaration due to event type incompatability
    let ent = e.target as HTMLFormElement;
    ent.classList.remove("error");
  }

  function today(){
    return new Date().toISOString().split("T")[0];
  }

  function ModalForm(){
    if(modalState === "newClaimant"){
      return(
        MakePartyInputForm("claimant")
      )
    }
    else if(modalState === "newRespondent"){
      return(
        MakePartyInputForm("respondent") 
      )
    }
    else if(modalState === "newAdvisor"){
      return(
        MakePartyInputForm("advisor")
      ) 
    }
    else if(modalState === "newWitness") { // witness
      return(
        MakePartyInputForm("witness")
      )
    }
    else if(modalState === "newParty"){
      return(
        MakePartyInputForm("")
      )
    }
    else{
      return(
        MakePartyInputForm("")
      )
    }
    if(modalState === "edit"){ // TODO: edit an existing party
      return(
        <form>
          <div className="sectionHeader">Edit an existing entry</div>
          <div className="inputGroup space_top">
            <label htmlFor="name">Name:</label>
            <input name="name" placeholder="Witness name" />
            <input name="role" type="hidden" value="witness" />
          </div>
          <div className="inputGroup">
            <label htmlFor="phone">Phone number:</label>
            <input type="tel" name="phone" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$" placeholder="Do you know their phone number?" />
          </div>
          <div className="inputGroup">
            <label htmlFor="email">Email address:</label>
            <input type="email" name="email" placeholder="Do you know their email address?" />
          </div>
          <div className="inputGroup">
            <label htmlFor="dob">Date of Birth:</label>
            <input type="date" name="dob" placeholder="Do you know their date of birth?" />
          </div>
          <button className="simple_button" onClick={ processForm }>Add Respondent</button><button className="simple_button" type="reset">Start Over</button>
        </form>
      )
    }
    else{
      return(
        <div>Nothing to see here.</div>
      )
    }
  }

  
  return(
    <form id="intakeForm">
      <Modal overlayClassName="modalOverlay" ariaHideApp={false} className="modalContent" isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}>
          <button className="modalClose" onClick={setModalIsOpenToFalse}></button>
          <ModalForm />
      </Modal>

      <section>
        <div className="inputWrapper">
          <div className="sectionHeader">If you’re comfortable, tell us who you are and how we can reach you for more information.</div>
          <div className="caption">This is to make sure we can reach you — and if you’d prefer to remain anonymous you can skip all of this.</div>
          <div className="inputGroup space_top">
            <label htmlFor="name">Your name:</label>
            <input name="name" onKeyDown={ (e) => removeError(e) } placeholder="Your full name" />
          </div>
          <div className="inputGroup">
            <label htmlFor="phone">Phone number:</label>
            <input type="tel" onKeyDown={ (e) => removeError(e) } name="phone" pattern="^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$" placeholder="Where can we call you if needed?" />
          </div>
          <div className="inputGroup">
            <label htmlFor="email">Email address:</label>
            <input type="email" onKeyDown={ (e) => removeError(e) } name="email" placeholder="Where can we email you if needed?" />
          </div>
          <div className="inputGroup ">
            <label htmlFor="ngb_affiliation">Your sport/organization affiliation:</label>
            <NGBSelect />
          </div>
          <div className="inputGroup ">
            <label htmlFor="clubAffiliation">Club affiliation:</label>
            <input name="clubAffiliation" placeholder="Skip if not applicable" />
          </div>
          <div className="inputGroup ">
            <label htmlFor="orgRole">Your role:</label>
            { MakeRoleSelect() }
          </div>
          <div className="inputGroup ">
            <label htmlFor="role">What is your relation to the claimant?</label>
            <div className="mobileSelectWrapper">
              <select defaultValue="" name="role" id="relationship" onChange={ addReporterToParties }>
                <option value=""></option>
                <option value="claimant">I am a claimant</option>
                <option value="witness">I am a witness</option>
                <option value="advisor">I am an advisor</option>
                <option value="tpr">Other</option>
              </select>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="inputWrapper">
          <div className="sectionHeader">Then, tell us about the event</div>
          <div className="caption">This is to make sure our intake team knows how to get this to the right investigators.</div>
          <div className="inputGroup space_top">
            <div id="character_count">{ incidentCharCount } of 3000 characters remaining</div>
          </div>
          <div className="inputGroup">
            <label htmlFor="incident_summary">What happened?</label>
            <textarea rows={15} maxLength={3000} name="incident_summary" placeholder="Please be as detailed as you can about the incident (who, what, when)" onKeyDown={ (e) => removeError(e) } onKeyUp={ updateCharCount }></textarea>
          </div>
          <div className="inputGroup narrow">
            <label htmlFor="incident_location">Location in which this occured:</label>
            <input type="location" name="incident_location" placeholder="City, State" onKeyDown={ (e) => removeError(e) } />
          </div>
          <div className="inputGroup narrow">
            <label htmlFor="incident_environment">In which environment?</label>
            <div className="mobileSelectWrapper">
              <select defaultValue="other" name="incident_environment">
                <option value="gym">Gym</option>
                <option value="locker room">Locker Room</option>
                <option value="facility">Facility</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="multipleDates">Incident spans multiple dates</label>
            <input type="checkbox" className="borderless" name="multipleDates" onChange={ (e:React.ChangeEvent<HTMLInputElement>) => setMultipleDates(e.target.checked) } />
          </div>
          { multipleDates && 
            <>
              <div className="inputGroup narrow">
                <label htmlFor="incident_start_date">Start date:</label>
                <input type="date" name="incident_start_date" max={ today() } onChange={ (e: ChangeEvent) => removeErrorViaChange(e) } />
              </div>
              <div className="inputGroup narrow">
                <label htmlFor="incident_end_date">End date:</label>
                <input type="date" name="incident_end_date" max={ today() } onChange={ (e: ChangeEvent) => removeErrorViaChange(e) } />
              </div>
            </>
          }
          { !multipleDates && 
            <>
              <div className="inputGroup narrow">
                <label htmlFor="incident_date">Date this occured:</label>
                <input type="date" name="incident_date" max={ today() } onChange={ (e: ChangeEvent) => removeErrorViaChange(e) } />
              </div>
            </>
          }
          <div className="inputGroup narrow">
            <label htmlFor="reported_to_lawenforcement">Reported to Law Enforcement?</label>
            <div className="mobileSelectWrapper">
              <select defaultValue="" name="reported_to_lawenforcement">
                <option value="">Don't know</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="inputGroup narrow">
            <label htmlFor="minor_involved">Did this incident involve a minor?</label>
            <div className="mobileSelectWrapper">
              <select defaultValue="" name="minor_involved">
                <option value="">Don't know</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="inputGroup narrow">
            <label htmlFor="type_of_concern">Type of Concern?</label>
            <div className="mobileSelectWrapper">
              <select defaultValue="" name="type_of_concern">
                <option value="">Not sure</option>
                <option value="bullying">Bullying</option>
                <option value="emotional misconduct">Emotional Misconduct</option>
                <option value="ethical violation">Ethical Violation</option>
                <option value="harassment">Harassment</option>
                <option value="physical misconduct">Physical Misconduct</option>
                <option value="sexual misconduct">Sexual Misconduct</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="inputWrapper">
          <div className="sectionHeader">Lastly, tell us who was involved</div>
          <div className="caption">Anything you can provide us about the respondents, claimants or witnesses is helpful.</div>
          { missingRespondent && 
            <div className="error caption">You must have at least one respondent in order to submit this form.</div>
          }
        </div>
        { partiesViewAsIcons && 
          <div>
            <div className="inputWrapper">
              <div className="viewToggle">
                <button className="simple_button" onClick={ e => setPartiesViewAsIcons(false) }>Switch to List View</button>
              </div>
            </div>
            <div className="partiesWrapper">
              { partiesList.map( (party, i:number) => <div key={ "party" + i} id={i + "p"} onClick={ makeEditModal } className="partyIcon"><p className="name">{ getPartyName(party.name) }</p><p>{ getUXString(party.role) }</p></div>) }

              { ["respondent", "claimant"].filter(roleIsNeeded, partiesList).map( (r, i:number) => <div className="partiesAdd" key={"p"+i} id={ getID(r)} onClick={ makeOpenModal }>Add a {getUXString(r)} to this case</div>)}

              <div className="partiesAdd" id="newWitness" onClick={ makeOpenModal }>Add a Witness to this case</div>
              <div className="partiesAdd" id="newAdvisor" onClick={ makeOpenModal }>Add an Advisor to this case</div>
            </div>
          </div>
        }
        { !partiesViewAsIcons && 
          <div>
            <div className="inputWrapper">
              <div className="viewToggle">
                <button className="simple_button" onClick={ e => setPartiesViewAsIcons(true) }>Switch to Icons View</button>
              </div>
            </div>
            <div className="inputWrapper">
              <table className="bordered">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Relation</td>
                    <td>NGB Affiliation(s)</td>
                    <td>Role</td>
                    <td>Club Affiliation</td>
                    <td>Phone number</td>
                    <td>Email address</td>
                    <td>Date of birth</td>
                  </tr>
                </thead>
                <tbody>
                  { partiesList.map( (party, i:number) => 
                    <tr key={ "party" + i} className="">
                      <td className="name">{ getPartyName(party.name) }</td>
                      <td>{ getTextForRole(party.role) }</td>
                      <td>{ party.sportOrgAffiliation.join(", ") }</td>
                      <td>{ party.orgRole }</td>
                      <td>{ party.clubAffiliation }</td>
                      <td>{ party.phoneNumber }</td>
                      <td>{ party.email }</td>
                      <td>{ party.dateOfBirth }</td>
                    </tr>) }
                  <tr><td colSpan={ 6} className="partiesAdd partiesAddSmaller" id="newParty" onClick={ makeOpenModal }>Add a new person to this case</td></tr>
                </tbody>
              </table>
            </div>
          </div>
          
        }
        
        
      </section>
      <section>
        <div className="inputWrapper">
          <div className="sectionHeader">Now, we’ll open this incident report</div>
          <div className="caption">On the next screen, you can add any images, videos or files to this report.</div>
        </div>
        <div className="buttonWrapper">
          <input type="submit" className="simple_button" onClick={ submitIncident } value="Submit New Intake" /><button type="reset" onClick={ resetForm } className="simple_button">Start Over</button>
        </div>
      </section>
    </form>
  )
}



function InitialForm() {
  return (
    <>
      <Intro />
      <Form />
    </>
  );
}


ReactDOM.render(
  <React.StrictMode>
    <InitialForm />
  </React.StrictMode>,
  document.getElementById('root')
);



export default InitialForm;